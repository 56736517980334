@charset "utf-8";

html {
    overflow-y:scroll;
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6,p, th, td, img {
	margin: 0;
	padding: 0;
	border: 0;
}

body {
	background: #fff;
	color: #333;
	font-size: 75%; /* フォントサイズ指定(%はブラウザに設定した標準文字サイズ(通常16px)に対する値)*/
	font-family: "メイリオ", Meiryo, "ＭＳ Ｐゴシック", Osaka, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro"; /*フォントの種類指定(左から優先順位)*/
	line-height: 1;	/*行の高さ指定(数値のみの場合はfont-sizeの倍数)*/
}

/* -------------------------------------  ソート用  ------------------------------------- */
#mainTable{
	font-size: 16px;
	font-family: 'ＭＳ Ｐゴシック',sans-serif;
	text-align: center;
	vertical-align: middle;
	width: 360px;
	margin-top: 0px;
	margin-left: auto;
	margin-right: auto;
	/* border: 1px solid #d3d3d3; */
}
#questionField {
    height: 150px;
	width: 180px;
    /* border: 1px solid #d3d3d3; */
    background-color: #DCDCDC;
    /* cursor: pointer; */
}
#leftItemField{
	height: 100px;
	width: 180px;
	border: 1px solid #d3d3d3;
	background-color: #FFFFFF;
	/* cursor:pointer; */
}
#rightItemField{
	height: 100px;
	width: 180px;
	border: 1px solid #d3d3d3;
	background-color: #FFFFFF;
	/* cursor:pointer; */
}
#leftVoteCountField{
	height: 50px;
	width: 180px;
	border: 1px solid #d3d3d3;
	background-color: #FFFFFF;
	/* cursor:pointer; */
}
#rightVoteCountField{
	height: 50px;
	width: 180px;
	border: 1px solid #d3d3d3;
	background-color: #FFFFFF;
	/* cursor:pointer; */
}
#leftField{
	height: 150px;
	width: 180px;
	border: 1px solid #d3d3d3;
	background-color: #FFFFFF;
	/* cursor:pointer; */
}
#rightField{
	height: 150px;
	width: 180px;
	border: 1px solid #d3d3d3;
	background-color: #FFFFFF;
	/* cursor:pointer; */
}
#middleField {
    height: 150px;
	width: 180px;
}
#commentField {
    height: 180px;
	width: 180px;
	padding: 11px;
  	border: none;
  	font-size: 18px;
  	background: #e9f3e9; /*入力スペースの背景色*/
}
#commentInput {
    width: 80%; /*親要素いっぱい広げる*/
    padding: 10px 15px; /*ボックスを大きくする*/
    font-size: 16px;
    border-radius: 3px; /*ボックス角の丸み*/
    border: 2px solid #ddd; /*枠線*/
    box-sizing: border-box; /*横幅の解釈をpadding, borderまでとする*/
}
#errMsgField {
    height: 50px;
	width: 180px;
}
#voteInput {
	position: relative;
  	color: #a0a0a0;
  	outline: none;
	width: 230px;
}
#voteButton {
	cursor: pointer;
}